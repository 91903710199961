;(function(window, $) {
  $(function() {

    var $logout_confirm = $('#logout-confirm');

    if($logout_confirm.length > 0) {

      $('.logout-link').click(function(e) {
        e.preventDefault();
        $logout_confirm.modal('show');
      });

    }

  });
})(window, jQuery);
