;(function(window, $) {
  $(function() {
    $('body').tooltip({
      selector: '[data-toggle="tooltip"]',
      delay: {
        show: 250,
        hide: 100
      },
      placement: function(tooltip, trigger) {
        var placement_attr = $(trigger).data('placement');
        var placement_default = placement_attr ? placement_attr : 'auto';
        var placement_current = $(window).width() > 768 ? placement_default : 'auto top';
        return placement_current;
      }
    });
  });
})(window, jQuery);
