;(function(window, $) {
  $(function () {
    $('#main-nav').on('shown.bs.collapse', function () {
      $(this).find('.dropdown-toggle').each(function() {
        if( $(this).closest('.dropdown').hasClass('open') === false ) {
          $(this).dropdown('toggle');
        }
      });
    });
  });
})(window, jQuery);
